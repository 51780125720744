body{
    background-color: #a9a8ac41;
    overflow-x: auto;
    margin: 0;
}
/* Main Responsive */
#main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
  margin-left: auto;
}
.menu-toggle{
  font-size: 30px;
  cursor: pointer;
  visibility: hidden;
  color: #fff;
}
@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}
@media (min-width: 1200px) {
  #main {
    margin-left: 300px;
  }
}



#botonPag{
    background-color: #00344D;
    color: white;
    margin-left: 10px;
}

#background-login {
    background-image: url('../public/files/background_login.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    position: fixed;
}

@media (max-width: 768px) {
  #background-login {
      background-size: auto;
  }
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header {
  background: linear-gradient(to right, #362b2b, #cf8336) !important;
  color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}

.card-header1 {
  background: linear-gradient(to right, #2b2e36, #49c2ec) !important;
  color: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}

.card-header4 {
  background: linear-gradient(to right, #200505, #a81010 ) !important;
  color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}

.card-header2 {
  background: linear-gradient(to right, #a86008, #943126 ) !important;
  color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}
.card-header5 {
  background: linear-gradient(to right, #03133f, #5e7aa3 ) !important;
  color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}

.card-header3 {
  background: linear-gradient(to right, #0a504a, #38ef7d) !important;
  color: #fff;
  border-radius: 10px;
  border: none;
  position: relative;
  box-shadow: 0 0.46875rem 2.1875rem rgba(90,97,105,0.1), 0 0.9375rem 1.40625rem rgba(90,97,105,0.1), 0 0.25rem 0.53125rem rgba(90,97,105,0.12), 0 0.125rem 0.1875rem rgba(90,97,105,0.1);
}

#form-login{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-49%, -49%);
  opacity: 0.85;
}

.form-register{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-49%, -49%);
  opacity: 0.85;
}

.obrasTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#btnDelete{
  margin-left: 10px;
}
#inputSearch{
  width: 350px;
}


/*modal de la obra*/
#btn-cancel{
  margin-right: 10px;
}
/* #table{
  margin-top: 70px;
} */
#table-body{
  background-color: #d6dbdf;
}
#table-header{
  background-color: #00344D;
  color: white;
  /* font-size: larger; */
}

/*Formulario de Registro Estudiante*/
#msform {
  text-align: center;
  position: relative;
  width: 100%;
}
#formCD{
  text-align: center;
  position: relative;
  width: 100%;
}
#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 10px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 90%;
  margin: auto;
  position: relative;
  line-height: 10px;
}
#msform fieldset:not(:first-of-type) {
  display: none;
}
#msform input:focus, #msform textarea:focus {
  -moz-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #106afcaf;
  outline-width: 0;
}


/*buttons*/
#msform .action-button {
  width: 250px;
  background: #106cfc;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}
#msform .action-button:hover, #msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #106cfc;
}
#msform .action-button-previous {
  width: 200px;
  background: #106cfc;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}
#msform .action-button-previous:hover, #msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #106cfc;
}



/*headings*/
.fs-title {
  font-size: 30px;
  text-transform: uppercase;
  color: #2C3E50;
  margin-bottom: 10px;
  letter-spacing: 3px;
  font-weight: bold;
}
.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}
fieldset .form-label{
  float: left;
}


/* Informacion del uisuario logueado */ 
#user-loged{
  margin-right: 30px;
}


/* Sidebar se oculta y toma otras propiedades */
.sidebar-sh {
  background: #29363d;
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  padding: 5px;
  z-index: 996;
  transition: all 0.4s ease-in-out;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.sidebar-sh > ul, li{
  display: block;
  padding: 10px;
  list-style: none;
  cursor: pointer;
}
.sidebar-sh .dropd{
  text-decoration: none;
  color: #fff;
  width: 100%;
  height: 45px;
  text-align: left;
  padding: 3px 10px;
  border: none;
  border-radius: 0px;
  font-size: 15px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
.sidebar-sh .uldrop{
  padding: 12px 10px;
}

/*Navbar y Sidebar*/
.sidebar{
  background: #29363d;
  position: fixed;
  color: #fff;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  padding: 5px;
  z-index: 996;
  transition: all 0.4s;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.sidebar > ul, li{
  display: block;
  padding: 12px;
  list-style: none;
}
.sidebar .link{
  text-decoration: none;
  color: #fff;
  padding: 13px;
}
.sidebar .dropd{
  text-decoration: none;
  color: #fff;
  width: 100%;
  height: 45px;
  text-align: left;
  padding: 9px 10px;
  border: none;
  border-radius: 0px;
  font-size: 15px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
.dropd:hover{
  background: #20a8d8;
  cursor: pointer;
  color: #fff;
  transition: all 0.8s ease;
}
.drop{
  padding: 0px 0px; 
  margin: 0;
}
.sidebar .uldrop{
  padding: 12px 10px;
}

#addInv{
  float: right;
  margin-bottom: 15px;
}

.centered-title {
  width: 100%;
  text-align: center;
}

.centered-body {
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.loader {
  width: 200px;
  height: 140px;
  background: #00344d;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0; 
  top: 0;
  margin: auto; 
  border-radius:8px;
  perspective: 1000px;
}

.loader:before{
  content: '';
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  border-radius:8px;
  background: #f2f3f3  no-repeat;
  background-size: 60px 10px;
  background-image: 	linear-gradient(#ddd 100px, transparent 0) ,
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
  
  background-position: 15px 30px , 15px 60px , 15px 90px, 
            105px 30px , 105px 60px , 105px 90px;
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
}
.loader:after {
  content: '';
    position: absolute;
    width: calc(50% - 10px);
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #fff no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
    background-position: 50% 30px ,50% 60px , 50%  90px;
    transform: rotateY(0deg );
    transform-origin: left center;
  animation: paging 1s linear infinite;
}


@keyframes paging {
  to {
    transform: rotateY( -180deg );
  }
}

/*Responsive*/
@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
    color: #fff;
  }
  .sidebar-sh {
    background: #29363d;
    position: fixed;
    color: #fff;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    padding: 5px;
    z-index: 996;
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
  .sidebar-sh > ul, li{
    display: block;
    padding: 10px;
    list-style: none;
    cursor: pointer;
  }
  .sidebar-sh .dropd{
    text-decoration: none;
    color: #fff;
    width: 100%;
    height: 45px;
    text-align: left;
    padding: 3px 10px;
    border: none;
    border-radius: 0px;
    font-size: 15px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }
  .sidebar-sh .uldrop{
    padding: 12px 10px;
  }
  .menu-toggle{
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    visibility: visible;
  }  
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}



/* position: absolute;
left: 0;
bottom: 0;
right: 0; 
top: 0; 
margin: auto;  */


/* @media (min-width: 1200px) {

  .toggle-sidebar #main {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
} */