/*Formulario de Login*/
.form-login{
    margin: auto;
    max-width: 400px;
    height: 540px;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-top: 2em;
    line-height: 15px;
    border-radius: 5%;
  }
  .logo-log{
    max-width: 350px;
  }
  .input-group{
    margin: auto;
    width: 300px;
  }
  .input-group-inv{
    margin: auto;
    width: 315px;
  }
  .login-label{
    font-weight: bold;
    margin-left: -10em;
  }
  .input-name{
    font-weight: bold;
    margin-left: -10em;
  }
  .input-inst{
    font-weight: bold;
    margin-left: -6em;
  }
  .input-linea-inv{
    font-weight: bold;
    margin-left: -8em;
  }
  .login-email{
    font-weight: bold;
    margin-left: -10em;
  }
  #form-label-pass{
    font-weight: bold;
    margin-left: -13em;
  }
  .register{
    font-weight: bold;
  }
  .navbar{
    background: #00344d;
  }
  .navbar h1{
    color: #fff;
  }
  